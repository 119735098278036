@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*Preloader*/
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #051d38;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  opacity: 1;
  transition: opacity 5s ease;
}

.loader-container.finish {
  opacity: 0;
  pointer-events: none;
  /*display: none;*/
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ff4500;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  transition: opacity 2s ease;
}

body {
  display: flex;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}

.container {
  background-image: url(/static/media/herb-bg.08dfb58f.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  width: 100%;
  /*margin: auto;*/
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* HEADER */
.header {
  width: 100vw;
  height: 23vh;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  margin-bottom: 4em;
  background-image: url(/static/media/header-bg.4129dd3e.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-header {
  width: 100vw;
  height: 25%;
  padding: 50px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
  margin-bottom: 0.8em;
}

.header .brand,
.home-header .brand {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.header .before,
.home-header .before {
  font-family: 'Kaushan Script', cursive;
  font-size: 2.75em;
  color: orangered;
  line-height: 1;
  letter-spacing: 2px;
}

.header h2,
.home-header h2 {
  font-size: 2.25em;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.header .after,
.home-header .after {
  font-size: 2em;
  font-weight: 600;
  letter-spacing: 1.1em;
  line-height: 1;
  color: orangered;
  text-transform: uppercase;
}

/* CONTENT */
.content {
  position: relative;
  display: flex;
  padding-left: 5.5%;
  flex-direction: column;
  width: 50%;
  height: 60%;
  transition: height 1s ease;
}

.content.adjust-height {
  height: 10%;
}

.content .welcome {
  width: 100%;
  transition: all 2s ease;
}

.content .welcome.hide-welcome {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.content h2 {
  font-size: 6em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}

.content p {
  font-size: 1.25em;
  color: #fff;
  margin: 15px 0 25px 0;
  font-weight: 400;
  max-width: 500px;
}

/* BUTTONS */
.expand-search-btn {
  display: flex;
  outline: none;
  border: none;
  background: #ff4500;
  font-size: 1em;
  padding: 16px 34px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.02s ease;
}

.expand-search-btn.hide {
  background: transparent;
}

.food-info-btn {
  position: absolute;
  right: 50px;
  bottom: 30px;
  background: #ff4500;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  letter-spacing: 2px;
  padding: 15px 35px;
  transition: 0.2s;
  cursor: pointer;
}

/* SEARCH INPUT */
.search {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: 2s ease;
}

.search.hidden {
  -webkit-transform: translateY(85%);
          transform: translateY(85%);
  z-index: -10;
  opacity: 0;
}

.search .show-input {
  display: flex;
  align-items: center;
  width: 72.1%;
  min-width: 551px;
}

.input-results {
  display: grid;
  width: 100%;
  grid-template-areas:
    'home-btn form form form form'
    'space-1 list-container list-container list-container';
}

/*Input Rows*/
.input-span {
  min-height: 49px;
  max-height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in 2s;
}

.input-span.show {
  width: 100%;
}

.input-span.hide {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.home-btn {
  grid-area: home-btn;
  width: 26.87%;
  min-width: 26.87%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: #ff4500;
  font-size: 1em;
  padding: 15px 38px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  transition: all 0.02s ease 3s;
  cursor: pointer;
}

.home-btn-mobile {
  display: none;
  grid-area: home-btn-mobile;
  width: 15%;
  min-width: 15%;
  outline: none;
  border: none;
  background: #ff4500;
  font-size: 1.1em;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  transition: all 0.02s ease 3s;
  cursor: pointer;
}

.home-btn.hide {
  background: transparent;
}

.home-btn:hover .back-arrow.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

.back-arrow {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  max-width: 0;
  transition: all 0.3s ease;
}

.input-span form {
  grid-area: form;
  display: flex;
  width: 78.13%;
  height: 100%;
}

.input-span form input[type='text'] {
  padding: 15px;
  outline: none;
  border: none;
  width: 90%;
  height: 100%;
  opacity: 0;
  font-size: 1em;
  transition: all 0.5s ease-in 2s;
}

.input-span form input[type='text'].slide {
  opacity: 1;
}

.search-icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  max-height: 100%;
  border: none;
  outline: none;
  background: #ff4500;
  font-size: 1.1em;
  font-weight: 500;
  color: #fff;

  opacity: 0;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease 2.5s;
}

.search-icon-btn.show-icon {
  opacity: 1;
}

.search-icon-btn i:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*Herb List Row*/
.herb-list-span {
  width: 100%;
  display: flex;
}

.herb-list-span .space-1 {
  grid-area: space-1;
  width: 26.87%;
}

/* HERBLIST UL */
.herb-list-container {
  grid-area: list-container;
  width: 73.13%;
}

.herb-list {
  list-style-type: none;
  height: auto;
  width: 90%;
}

.space-2 {
  width: 10%;
}

.herb-list.hide {
  display: none;
}

.herb-list li {
  background: rgba(7, 61, 116, 0.5);
  padding-left: 20px;
  color: orangered;
  color: #f5f5f5;
  transition: 0.2s ease;
}

.herb-list li:hover {
  background: rgba(7, 61, 116, 0.9);
}

.herb-list li.current {
  background: rgba(7, 61, 116, 0.9);
}

.herb-list li:focus {
  outline: none;
}

.herb-list li.hide {
  display: none;
}

/*ALERTS*/
.search .alert {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  width: 50%;
  opacity: 0.9;
  background: #fff;
  color: #333;
  margin: 20px 119px;
  opacity: 0;
  transition: all 1s ease;
}

.search .alert.show {
  opacity: 1;
}

.search .alert .alert-icon {
  background: orange;
  color: #fff;
  padding: 8px 20px;
  height: 100%;
}

.search .alert .message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.search .alert {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  width: 50%;
  opacity: 0.9;
  background: #fff;
  color: #333;
  margin: 20px 119px;
  opacity: 0;
  transition: all 1s ease;
}

.search .alert.show {
  opacity: 1;
}

.search .alert .alert-icon {
  background: orange;
  color: white;
  padding: 8px 20px;
  height: 100%;
}

.search .alert .message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/*Link to Form Button*/
.to-herb-form-btn {
  position: absolute;
  bottom: 10%;
  right: 3%;
  display: flex;
  outline: none;
  border: none;
  box-shadow: 2px 2px 3px rgba(129, 142, 155, 0.5);
  font-size: 1.5em;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  border-radius: 50%;
  background: #ff4500;
  opacity: 0;
  transition: opacity 0.5s ease 3s, -webkit-transform 0.05s ease-in-out;
  transition: opacity 0.5s ease 3s, transform 0.05s ease-in-out;
  transition: opacity 0.5s ease 3s, transform 0.05s ease-in-out, -webkit-transform 0.05s ease-in-out;
}

.to-herb-form-btn.show {
  opacity: 1;
}

.to-herb-form-btn.show:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*HERB FORM */
.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: calc(100% - 20vh);
  width: 100vw;
}

.form-container form {
  display: flex;
  align-items: center;
  background: #f0e9e9e6;
  box-shadow: 1px 1px 5px rgba(129, 142, 155, 0.5);
  width: 75%;
  margin: 30px 0;
  padding: 40px;
  flex-direction: column;
  font-size: 1em;
  border-radius: 3px;
}

.form-container .herb-form-title {
  color: #0f4269;
  padding-bottom: 15px;
}

.form-container form .herb-form-btn {
  align-self: center;
  outline: none;
  border: none;
  border-radius: 3px;
  background: #0f4269;
  font-size: 16px;
  margin: 15px 0;
  padding: 20px 40px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
}

.form-group {
  display: flex;
  justify-content: space-between;
  margin: 3px 0;
  width: 100%;
}

.form-group input {
  border: 1px solid #dfdcdc;
  outline: none;
  padding: 15px;
  width: 75%;
  min-width: 60%;
  font-size: 1em;
}

.form-control-file {
  display: flex;
  color: rgba(0, 0, 0, 0);
}

.form-control-file.show {
  color: rgba(0, 0, 0, 1);
}

.form-control-file::file-selector-button {
  padding: 0.4em 0.8em;
  border-radius: 3px;
  margin-right: 15px;
  background: #0f4269;
  color: #fff;
  cursor: pointer;
}

.categories-group {
  display: flex;
  width: 100%;
  margin: 20px 0;
}

.categories-foods {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.categories-foods div {
  display: flex;
  justify-content: space-between;
  margin: 3px 0;
}

.categories-foods input {
  border: 1px solid #dfdcdc;
  outline: none;
  padding: 15px;
  width: 75%;
  min-width: 60%;
  font-size: 1em;
}

.herb-form-float-btns {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 10%;
  right: 3%;
}

.herb-list-float-btn,
.clear-form-float-btn {
  display: flex;
  outline: none;
  border: none;
  box-shadow: 2px 2px 3px rgba(129, 142, 155, 0.5);
  font-size: 1.4em;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 2px;
  border-radius: 50%;
  margin: 10px 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.herb-list-float-btn {
  background: #0f4269;
}

.clear-form-float-btn {
  background: #ff4500;
}

.herb-list-float-btn:hover,
.clear-form-float-btn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

#herb-form-tooltip,
#add-herb-tooltip,
#active-icon-tooltip {
  background: #0f395e !important;
}

/*HERB CARDS PAGE*/
.herb-list-cards-container {
  position: relative;
  height: auto;
  width: 100%;
  /* background: #f5f5f5;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3%;
  margin: auto;
}

.herb-list-back-btn {
  background: transparent;
  padding: 10px 20px;
  border-radius: 6px;
  color: #0f4269;
  font-size: 1.5em;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.herb-list-back-btn:hover {
  background: #0f4269;
  color: #fff;
  box-shadow: 2px 2px 3px rgba(129, 142, 155, 0.5);
}

.herb-list-filter {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 60px 100px;
}

.herb-list-input {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #f7f5f5;
  border: 1px solid #dfdcdc;
  border-radius: 4px;
  outline: none;
  padding: 1em;
  font-size: 1em;
  width: 50%;
  align-self: center;
}

.herb-list-input:focus {
  background: #fff;
  box-shadow: 1px 1px 2px rgba(129, 142, 155, 0.3);
}

.herb-list-icon {
  position: absolute;
  right: 25%;
  color: #134972;
  font-size: 1.5em;
  padding: 1.3em;
  z-index: 100;
}

.herb-list-cards {
  width: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  gap: 5px;
}

.herb-list-card {
  width: 100%;
  padding: 20px;
  height: 300px;
  background: #f0e9e9e6;
  border-radius: 2px;
  overflow-y: scroll;
  font-size: 13px;
}

.herb-list-card-header {
  color: #125080;
  display: flex;
  justify-content: space-between;
}

.herb-list-card-btns i {
  color: #134972;
  font-size: 16px;
  margin: 4px;
}

.herb-list-card-btn {
  outline: none;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

.herb-list-card ul {
  list-style: none;
}

.herb-list-card .flex-row {
  display: flex;
}

.herb-list-card .flex-row h4 {
  display: flex;
  flex-wrap: nowrap;
  width: 3.125em;
  margin-right: 2.7em;
}
.herb-list-card .flex-row p {
  padding-left: 0.7em;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #134972;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #165483;
}

/*LOADER*/
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #02162a;
  color: #fff;
  overflow: hidden;
}

/*ALERTS*/
.alert {
  position: fixed;
  top: 35%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 1000;
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-primary {
  background: #0f395e;
  color: #fff;
}

.alert-orange {
  background: #ff4500;
  color: #fff;
}

.alert-dark {
  background: #333333;
  color: #fff;
}

.alert-danger {
  background: #dc3545;
  color: #fff;
}

.alert-success {
  background: #28a745;
  color: #fff;
}

.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

/*MEDIA QUERIES*/
@media (max-width: 1100px) {
  .header,
  .home-header {
    margin-bottom: 20px;
  }
  .header .brand,
  .home-header .brand {
    width: 100%;
  }

  .header .brand,
  .home-header .brand {
    width: 100%;
  }
  .header .before,
  .home-header .before {
    font-size: 3.5em;
    letter-spacing: 2px;
  }

  .header h2,
  .home-header h2 {
    font-size: 2.5em;
    letter-spacing: 3px;
  }

  .header .after,
  .home-header .after {
    font-size: 2.5em;
    letter-spacing: 12px;
  }

  .content .welcome {
    margin-top: 5px;
  }

  .content h2 {
    font-size: 7em;
  }

  .content p {
    font-size: 1.5em;
    color: #fff;
    margin: 15px 0 35px 0;
  }

  .expand-search-btn,
  .food-info-btn,
  .form-container form .herb-form-btn {
    font-size: 1em;
    padding: 15px 32px;
  }

  .to-herb-form-btn,
  .herb-list-float-btn,
  .clear-form-float-btn {
    bottom: 2%;
    padding: 20px 20px;
  }

  .form-container form {
    width: 75%;
  }

  .herb-list-filter {
    margin: 0 0 20px 0;
    padding: 0 20px;
  }

  .herb-list-cards-container .herb-list-input {
    width: 75%;
  }

  .herb-list-cards-container .herb-list-icon {
    right: 12%;
  }

  .herb-list-cards-container .herb-list-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

/*styles for anything under 768px*/
@media (max-width: 768px) {
  .header,
  .home-header {
    padding: 20px;
  }

  .home-header {
    margin-bottom: 20px;
    height: 20%;
  }

  .header .brand,
  .home-header .brand {
    width: 100%;
  }

  .header .before,
  .home-header .before {
    font-size: 2.5em;
  }

  .header h2,
  .home-header h2 {
    font-size: 2.5em;
  }

  .header .after,
  .home-header .after {
    font-size: 2em;
    letter-spacing: 12px;
  }

  .content {
    width: 100%;
  }

  .content h2 {
    font-size: 3.325em;
  }

  .content p {
    font-size: 1.1em;
    max-width: 200px;
  }

  .expand-search-btn,
  .form-container form .herb-form-btn {
    font-size: 15px;
    padding: 12px 24px;
    letter-spacing: 1px;
  }

  .food-info-btn {
    font-size: 13px;
    padding: 9px 18px;
    letter-spacing: 1px;
  }

  .home-btn {
    display: none;
  }

  .home-btn-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 12px 8px;
    background: transparent;
  }

  .input-results {
    width: 85%;
  }
  .search .show-input {
    display: flex;
    align-items: center;
    width: 95%;
    min-width: 95%;
  }

  .input-span {
    min-height: 40px;
    max-height: 40px;
  }

  .input-span form {
    font-size: 15px;
    width: 85%;
  }

  .herb-list {
    font-size: 14px;
  }

  .herb-list-span .space-1 {
    width: 15%;
  }

  .herb-list-container {
    width: 85%;
  }

  /*ADD HERB FORM*/
  .herb-form-float-btns {
    bottom: 2%;
    right: 5%;
    margin: 0;
  }

  .to-herb-form-btn {
    bottom: 4%;
    right: 3%;
    margin: 0;
  }

  .to-herb-form-btn,
  .herb-list-float-btn,
  .clear-form-float-btn {
    font-size: 12px;
    padding: 16px 16px;
    margin: 4px;
  }

  .form-container form {
    width: 95%;
    padding: 20px;
    margin: 0;
  }

  .form-container .herb-form-title {
    font-size: 16px;
  }

  .form-container .form-group,
  .categories-group {
    font-size: 13px;
  }

  .form-container .form-group label,
  .categories-group label {
    width: 20px;
  }

  .categories-title label {
    font-weight: bold;
  }

  .form-container .form-group input {
    width: 75%;
    min-width: 70%;
    min-height: 40px;
    max-height: 40px;
  }

  .form-group .form-control-file {
    min-height: 60px;
    max-height: 60px;
    padding-bottom: 40px;
  }

  .categories-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .categories-foods input {
    width: 75%;
    min-width: 70%;
    min-height: 40px;
    max-height: 40px;
  }

  .form-container form .herb-form-btn {
    font-size: 14px;
    padding: 14px 28px;
  }

  /*HERB CARDS LIST*/
  .herb-list-filter {
    padding: 0 10px;
  }
  .herb-list-back-btn {
    padding: 8px 16px;
    font-size: 1em;
  }

  .herb-list-cards-container .herb-list-input {
    margin-left: 10px;
    padding: 14px;
    font-size: 1em;
  }

  .herb-list-cards-container .herb-list-icon {
    font-size: 1.2em;
  }

  .herb-list-cards-container .herb-list-cards {
    grid-template-columns: 1fr;
  }

  .herb-list-cards .herb-list-card {
    padding: 1.25em;
    height: 200px;
    font-size: 0.875em;
  }

  .herb-list-card-header {
    font-size: 0.875em;
  }

  .herb-list-card .herb-list-card-btns i {
    font-size: 1em;
  }

  .herb-list-card .flex-row h4 {
    width: 3.125em;
    margin-right: 2.7em;
  }
  .herb-list-card .flex-row p {
    padding-left: 0.625em;
  }
  ::-webkit-scrollbar {
    width: 1em;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*CARD*/
.card {
  overflow-y: hidden;
  color: rgb(6, 52, 99);
  width: 60%;
  border-radius: 3px;
  min-height: 450px;
  margin: 25px 5.5%;
  -webkit-transform: translateY(120%);
          transform: translateY(120%);
  transition: all 0.5s ease-in;
}

.card.show {
  -webkit-transform: translateY(-70%);
          transform: translateY(-70%);
}

.card .card-header h1 {
  background: rgba(14, 73, 119, 0.95);
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 15px;
}

/* CARD CONTENT */
.card .card-content {
  position: relative;
  height: 90%;
}

.card-content .info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  padding: 0 20px;
  background: rgb(252, 246, 246);
}

/* CARD IMAGE */
.info .herb-img-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: all 0.7s ease;
  z-index: 100;
  display: block;
}

.info .herb-img-container:hover,
.info .herb-img-container.hide {
  opacity: 0.1;
}

.info .herb-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/*CARD HERB GENERAL INFORMATION*/
.info .info-rows {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s ease;
}

.info-rows h4 {
  font-size: 14px;
}

.info .info-rows.show {
  opacity: 1;
}

.info-rows .row-one {
  width: 90%;
  height: 25%;
}

.row-one span {
  height: 100%;
}

.row-two,
.row-one p {
  font-size: 14px;
  min-width: 80%;
}

.info-rows .row-two {
  display: flex;
  width: 100%;
  height: 60%;
  visibility: visible;
  transition: visibility 0.2s ease;
}

.row-two.hide {
  visibility: hidden;
}

.row-two div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

.row-two .right-div {
  padding-left: 10px;
}

.row-two span {
  height: 40%;
}

.row-two p {
  min-height: 45%;
}

/* CARD ICONS */
.food-categories {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 18.5%;
  max-height: 18.5%;
  background: #0e4977;
  /* color: #fff;*/
}

.switch-bg {
  background: #f0e9e9e6;
}

.food-categories h4 {
  align-self: flex-start;
}

.food-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  margin-top: 10px;
}

.food-icons div {
  background-size: 28px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 10%;

  width: 11%;
  height: 100%;
  margin: 0 5px;
  padding: 38px 0 5px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  transition: 0.5s ease;
  color: #fff;
}

.food-icons div.switch-clr {
  color: #093868;
}

.food-icons div.active {
  color: #d63b03;
}

.food-icons div:hover {
  color: #d63b03;
}

.food-icons #grains {
  background-image: url(/static/media/bread.008d6f9d.svg);
}

.food-icons #grains:hover {
  background-image: url(/static/media/bread-orange.9a00bd99.svg);
}

.food-icons #meats {
  background-image: url(/static/media/meat.630ffc9c.svg);
}

.food-icons #meats:hover {
  background-image: url(/static/media/meat-orange.1dc0827e.svg);
}

.food-icons #seafood {
  background-image: url(/static/media/lobster.8e7b7afb.svg);
}

.food-icons #seafood:hover {
  background-image: url(/static/media/lobster-orange.55bb4388.svg);
}
.food-icons #vegetables {
  background-image: url(/static/media/broccoli.0af9222a.svg);
}

.food-icons #vegetables:hover {
  background-image: url(/static/media/broccoli-orange.fb37fd47.svg);
}
.food-icons #beverages {
  background-image: url(/static/media/lemonade.a69ad3c1.svg);
}

.food-icons #beverages:hover {
  background-image: url(/static/media/lemonade-orange.fe739605.svg);
}
.food-icons #soups {
  background-image: url(/static/media/soup.4ec961ad.svg);
}

.food-icons #soups:hover {
  background-image: url(/static/media/soup-orange.6b490045.svg);
}

.food-icons #desserts {
  background-image: url(/static/media/cupcake.7214278b.svg);
}

.food-icons #desserts:hover {
  background-image: url(/static/media/cupcake-orange.25a0aced.svg);
}
.food-icons #combos {
  background-image: url(/static/media/leaf.ebdaeb41.svg);
}

.food-icons #combos:hover {
  background-image: url(/static/media/leaf-orange.b37635fd.svg);
}

.food-icons #misc {
  background-image: url(/static/media/spoon.1c78ec78.svg);
}

.food-icons #misc:hover {
  background-image: url(/static/media/spoon-orange.0d21bd55.svg);
}

.food-icons .white-icon {
  height: 38px;
  width: 28px;
  padding-bottom: 5px;
  opacity: 1;
}

.food-icons .white-icon.hide {
  opacity: 0;
}

.food-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 96%;
  min-height: 224px;
  max-height: 224px;
  padding: 20px 20px;
  background: linear-gradient(rgb(20, 113, 184), rgb(0, 0, 7));
  color: #f5f5f5;
  font-size: 12px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  transition: -webkit-transform 0.8s ease;
  transition: transform 0.8s ease;
  transition: transform 0.8s ease, -webkit-transform 0.8s ease;
}

.food-info.show {
  z-index: 500;
  -webkit-transform: translateY(-111%);
          transform: translateY(-111%);
}

.food-info h3 {
  text-transform: capitalize;
}
.food-info p {
  max-width: 80%;
}

/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*CARD*/
.card-container {
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  z-index: 300;
  overflow: hidden;
  display: none;
}

.card-container.show {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*MEDIA QUERIES*/
@media (max-width: 1100px) {
  .card {
    width: 80%;
    min-height: 500px;
  }

  .card.show {
    -webkit-transform: translateY(-55%);
            transform: translateY(-55%);
  }

  .card .card-header h1 {
    padding: 15px 20px;
  }

  .food-icons {
    margin-top: 0px;
  }

  .food-icons div {
    background-size: 29px;
    font-size: 12px;
  }

  .food-info {
    font-size: 14px;
  }
}

/*styles for anything under 768px*/
@media (max-width: 768px) {
  .card-container {
  }

  .card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin: 0;
  }

  .card.show {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  /* CARD CONTENT */
  .card .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  /* CARD IMAGE */
  .info .herb-img-container {
    display: none;
  }

  .card-content .herb-img-container {
    position: relative;
    height: 40%;
  }

  .card-content .herb-img-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .card-header {
    width: 100%;
  }

  .card .card-header h1 {
    position: absolute;
    bottom: 16px;
    left: 10px;
    background: rgba(9, 56, 104, 0.9);
    padding: 8px 16px;
    font-size: 16px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 5%;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: none;
    background: rgba(9, 56, 104, 0.5);
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .close:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  /*CARD INFO*/
  .card-content .info {
    height: 50%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .info .info-rows {
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 0 25px 0;
  }

  .info-rows .info-row {
    padding: 0;
    width: 100%;
  }

  .info-rows .row-one {
    display: none;
  }

  .info-rows .info-row:not(:nth-of-type(1)) {
    border-top: solid 2px rgba(207, 201, 201, 0.432);
  }

  .info-row .info-row-title {
    width: 100%;
    padding: 4px 15px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .info-row .info-row-panel {
    width: 100%;
    min-height: 0;
    max-height: 0;
    padding: 3px 15px;
    line-height: 1;
    opacity: 0;
    visibility: hidden;
    font-size: 13px;
    color: #333;
    transition: all 0.4s ease;
  }

  .info-row .info-row-panel.active {
    min-height: 25px;
    max-height: auto;
    opacity: 1;
    visibility: visible;
  }

  .info-row .info-row-title.active,
  .info-row .info-row-title:hover {
    background: #093868;
    color: #fff;
  }

  /*FOOD CATEGORIES*/
  .food-categories {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 5px 5px;
    background: #fff;
  }

  .food-icons {
    display: grid;
    grid-template-columns: repeat(5, 17%);
    align-items: center;
    justify-content: center;
  }

  .food-icons div {
    background-color: rgba(9, 56, 104, 1);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    height: 25px;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    border: 2px solid #0e4977;
  }

  .food-icons div:hover {
    color: #d63b03;
    border-color: #d63b03;
    background-color: rgba(9, 56, 104, 0);
  }

  /*Food Info*/
  .food-info {
    position: absolute;
    top: 0;
    left: 2.5%;
    width: 95%;
    min-height: 230%;
    max-height: 230%;
    padding: 20px 20px;
    font-size: 13px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  .food-info.show {
    z-index: 500;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  .food-info p {
    max-width: 80%;
  }
}

.recipes-container {
  position: relative;
  height: calc(100% - 20vh);
  min-height: 100vh;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*RECIPE CARDS*/
.recipes-container .recipes-page-title {
  color: #0f4269;
  padding-top: 25px;
  font-size: 2.5rem;
  font-weight: 700;
}

.recipe-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 90%;
  height: auto;
  grid-gap: 15px;
  gap: 15px;
}

.recipe-cards .recipe-card {
  background: linear-gradient(rgb(20, 113, 184), rgb(0, 0, 7));
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 6px rgba(11, 34, 58, 0.5);
  border-radius: 2px;
}

.recipe-card-header img {
  object-fit: cover;
  height: 250px;
  width: 100%;
  visibility: visible;
}

.recipe-card-header img.hide {
  visibility: hidden;
}

.recipe-card-header h5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.87em;
  min-height: 53.6px;
  max-height: 53.6px;
  padding: 0 15px;
  text-transform: capitalize;
}

.recipe-card-header a {
  display: flex;
  text-decoration: none;
  color: #fff;
  max-height: 41.6px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.recipe-card-header .cuisine {
  font-size: 0.869em;
  font-weight: 500;
  line-height: 1;
  margin-top: 2px;
  padding: 0;
  color: #a3caf0;
}

.recipe-card-content {
  display: flex;
  flex-direction: column;
  padding: 15px 10px 20px 15px;
  font-size: 14px;
}

.recipe-card-content span {
  text-transform: capitalize;
  color: #fff;
}

.recipe-card-content .recipe-card-icons {
  display: flex;
  width: 100%;
}

.recipe-card-icons div {
  width: 33%;
  display: flex;
  flex-direction: column;
  margin: 2px;
}

.recipe-card-icons div span {
  display: flex;
  align-items: center;
}

.recipe-card-icons div span i {
  padding-right: 10px;
  visibility: visible;
}

.recipe-card-icons div span i.hide {
  visibility: hidden;
}

.recipe-card-icons div span p {
  font-weight: 500;
  color: orangered;
}

.recipe-card-icon-label {
  color: #fff;
  font-size: 0.869em;
}

.recipe-btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  visibility: visible;
}

.recipe-btn-div.hide {
  visibility: hidden;
}

.recipe-card-btn {
  display: inline-block;
  font-size: 12px;
  background: orangered;
  padding: 8px 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  /*margin-top: 10px;*/
  color: #fff;
  letter-spacing: 1px;
  transition: 0.2s;
}

/*CARD LOADERS*/
.animated-bg {
  background: #50535a;
  background: linear-gradient(
    to right,
    #50535a 0%,
    #565961 10%,
    #50535a 20%,
    #50535a 100%
  );

  background-size: 200% 100%;
  -webkit-animation: shimmer 2s linear infinite;
          animation: shimmer 2s linear infinite;
}

.animated-bg-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

/*MEDIA QUERIES*/
@media (max-width: 1100px) {
  .recipes-container .recipes-page-title {
    font-size: 2rem;
    padding-bottom: 15px;
    text-align: center;
  }
  .recipe-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .recipe-cards {
    grid-template-columns: repeat (2, 1fr);
  }

  .recipes-container .recipes-page-title {
    font-size: 1.5rem;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .recipe-cards {
    grid-template-columns: 1fr;
  }
}

