@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

/*CARD*/
.card {
  overflow-y: hidden;
  color: rgb(6, 52, 99);
  width: 60%;
  border-radius: 3px;
  min-height: 450px;
  margin: 25px 5.5%;
  transform: translateY(120%);
  transition: all 0.5s ease-in;
}

.card.show {
  transform: translateY(-70%);
}

.card .card-header h1 {
  background: rgba(14, 73, 119, 0.95);
  color: #f5f5f5;
  text-transform: uppercase;
  padding: 15px;
}

/* CARD CONTENT */
.card .card-content {
  position: relative;
  height: 90%;
}

.card-content .info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  padding: 0 20px;
  background: rgb(252, 246, 246);
}

/* CARD IMAGE */
.info .herb-img-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: all 0.7s ease;
  z-index: 100;
  display: block;
}

.info .herb-img-container:hover,
.info .herb-img-container.hide {
  opacity: 0.1;
}

.info .herb-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/*CARD HERB GENERAL INFORMATION*/
.info .info-rows {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s ease;
}

.info-rows h4 {
  font-size: 14px;
}

.info .info-rows.show {
  opacity: 1;
}

.info-rows .row-one {
  width: 90%;
  height: 25%;
}

.row-one span {
  height: 100%;
}

.row-two,
.row-one p {
  font-size: 14px;
  min-width: 80%;
}

.info-rows .row-two {
  display: flex;
  width: 100%;
  height: 60%;
  visibility: visible;
  transition: visibility 0.2s ease;
}

.row-two.hide {
  visibility: hidden;
}

.row-two div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
}

.row-two .right-div {
  padding-left: 10px;
}

.row-two span {
  height: 40%;
}

.row-two p {
  min-height: 45%;
}

/* CARD ICONS */
.food-categories {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 18.5%;
  max-height: 18.5%;
  background: #0e4977;
  /* color: #fff;*/
}

.switch-bg {
  background: #f0e9e9e6;
}

.food-categories h4 {
  align-self: flex-start;
}

.food-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  margin-top: 10px;
}

.food-icons div {
  background-size: 28px;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 10%;

  width: 11%;
  height: 100%;
  margin: 0 5px;
  padding: 38px 0 5px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  transition: 0.5s ease;
  color: #fff;
}

.food-icons div.switch-clr {
  color: #093868;
}

.food-icons div.active {
  color: #d63b03;
}

.food-icons div:hover {
  color: #d63b03;
}

.food-icons #grains {
  background-image: url('../icons/bread.svg');
}

.food-icons #grains:hover {
  background-image: url('../icons/bread-orange.svg');
}

.food-icons #meats {
  background-image: url('../icons/meat.svg');
}

.food-icons #meats:hover {
  background-image: url('../icons/meat-orange.svg');
}

.food-icons #seafood {
  background-image: url('../icons/lobster.svg');
}

.food-icons #seafood:hover {
  background-image: url('../icons/lobster-orange.svg');
}
.food-icons #vegetables {
  background-image: url('../icons/broccoli.svg');
}

.food-icons #vegetables:hover {
  background-image: url('../icons/broccoli-orange.svg');
}
.food-icons #beverages {
  background-image: url('../icons/lemonade.svg');
}

.food-icons #beverages:hover {
  background-image: url('../icons/lemonade-orange.svg');
}
.food-icons #soups {
  background-image: url('../icons/soup.svg');
}

.food-icons #soups:hover {
  background-image: url('../icons/soup-orange.svg');
}

.food-icons #desserts {
  background-image: url('../icons/cupcake.svg');
}

.food-icons #desserts:hover {
  background-image: url('../icons/cupcake-orange.svg');
}
.food-icons #combos {
  background-image: url('../icons/leaf.svg');
}

.food-icons #combos:hover {
  background-image: url('../icons/leaf-orange.svg');
}

.food-icons #misc {
  background-image: url('../icons/spoon.svg');
}

.food-icons #misc:hover {
  background-image: url('../icons/spoon-orange.svg');
}

.food-icons .white-icon {
  height: 38px;
  width: 28px;
  padding-bottom: 5px;
  opacity: 1;
}

.food-icons .white-icon.hide {
  opacity: 0;
}

.food-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 96%;
  min-height: 224px;
  max-height: 224px;
  padding: 20px 20px;
  background: linear-gradient(rgb(20, 113, 184), rgb(0, 0, 7));
  color: #f5f5f5;
  font-size: 12px;
  transform: translateY(100%);
  transition: transform 0.8s ease;
}

.food-info.show {
  z-index: 500;
  transform: translateY(-111%);
}

.food-info h3 {
  text-transform: capitalize;
}
.food-info p {
  max-width: 80%;
}

/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*CARD*/
.card-container {
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;
  z-index: 300;
  overflow: hidden;
  display: none;
}

.card-container.show {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*MEDIA QUERIES*/
@media (max-width: 1100px) {
  .card {
    width: 80%;
    min-height: 500px;
  }

  .card.show {
    transform: translateY(-55%);
  }

  .card .card-header h1 {
    padding: 15px 20px;
  }

  .food-icons {
    margin-top: 0px;
  }

  .food-icons div {
    background-size: 29px;
    font-size: 12px;
  }

  .food-info {
    font-size: 14px;
  }
}

/*styles for anything under 768px*/
@media (max-width: 768px) {
  .card-container {
  }

  .card {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin: 0;
  }

  .card.show {
    transform: translateY(0%);
  }

  /* CARD CONTENT */
  .card .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  /* CARD IMAGE */
  .info .herb-img-container {
    display: none;
  }

  .card-content .herb-img-container {
    position: relative;
    height: 40%;
  }

  .card-content .herb-img-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .card-header {
    width: 100%;
  }

  .card .card-header h1 {
    position: absolute;
    bottom: 16px;
    left: 10px;
    background: rgba(9, 56, 104, 0.9);
    padding: 8px 16px;
    font-size: 16px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 5%;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: none;
    background: rgba(9, 56, 104, 0.5);
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .close:hover {
    transform: scale(1.1);
  }

  /*CARD INFO*/
  .card-content .info {
    height: 50%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .info .info-rows {
    justify-content: space-between;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0 0 25px 0;
  }

  .info-rows .info-row {
    padding: 0;
    width: 100%;
  }

  .info-rows .row-one {
    display: none;
  }

  .info-rows .info-row:not(:nth-of-type(1)) {
    border-top: solid 2px rgba(207, 201, 201, 0.432);
  }

  .info-row .info-row-title {
    width: 100%;
    padding: 4px 15px;
    font-size: 15px;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .info-row .info-row-panel {
    width: 100%;
    min-height: 0;
    max-height: 0;
    padding: 3px 15px;
    line-height: 1;
    opacity: 0;
    visibility: hidden;
    font-size: 13px;
    color: #333;
    transition: all 0.4s ease;
  }

  .info-row .info-row-panel.active {
    min-height: 25px;
    max-height: auto;
    opacity: 1;
    visibility: visible;
  }

  .info-row .info-row-title.active,
  .info-row .info-row-title:hover {
    background: #093868;
    color: #fff;
  }

  /*FOOD CATEGORIES*/
  .food-categories {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 5px 5px;
    background: #fff;
  }

  .food-icons {
    display: grid;
    grid-template-columns: repeat(5, 17%);
    align-items: center;
    justify-content: center;
  }

  .food-icons div {
    background-color: rgba(9, 56, 104, 1);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    height: 25px;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    border: 2px solid #0e4977;
  }

  .food-icons div:hover {
    color: #d63b03;
    border-color: #d63b03;
    background-color: rgba(9, 56, 104, 0);
  }

  /*Food Info*/
  .food-info {
    position: absolute;
    top: 0;
    left: 2.5%;
    width: 95%;
    min-height: 230%;
    max-height: 230%;
    padding: 20px 20px;
    font-size: 13px;
    transform: translateY(100%);
  }

  .food-info.show {
    z-index: 500;
    transform: translateY(-100%);
  }

  .food-info p {
    max-width: 80%;
  }
}
